<template>
  <v-dialog v-model="dialog" :max-width="editing ? '80%' : '450px'">
    <v-card :loading="loading">
      <v-card-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col :cols="editing ? 5 : 12">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="form.name"
                      label="Tên bản đồ"
                      :rules="[(v) => !!v || 'Bạn chưa nhập tên bản đồ']"
                    ></v-text-field>
                    <v-select
                      label="Loại bản đồ"
                      value="skymap"
                      disabled
                      :items="['skymap', 'google']"
                    ></v-select
                  ></v-col>
                </v-row>

                <v-row v-if="editing">
                  <v-col cols="6">
                    <v-select
                      v-model="layer.placeTypeId"
                      :items="placeTypes"
                      item-text="name"
                      label="Loại địa điểm"
                      item-value="id"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      hide-details
                      v-model="layer.icon"
                      :items="icons"
                      label="Icon"
                      item-text="name"
                      item-value="key"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-switch
                      class="mt-1"
                      v-model="layer.label"
                      hide-details
                      label="Label"
                    ></v-switch>
                  </v-col>
                  <v-col v-if="layer.label" cols="4" class="py-0">
                    <v-text-field
                      hide-details
                      label="Màu"
                      v-model="layer.color"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="layer.label" cols="4" class="py-0">
                    <v-text-field
                      hide-details
                      label="Cỡ chữ"
                      v-model="layer.textSize"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="layer.label" cols="4" class="py-0">
                    <v-text-field
                      type="number"
                      hide-details
                      label="Offset"
                      :min="0"
                      v-model="layer.offset"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="blue" class="mt-2" @click="addLayer" x-small
                      >Thêm lớp</v-btn
                    ></v-col
                  >

                  <v-col cols="12">
                    <v-data-table
                      style="width: 100%"
                      :headers="[
                        { text: 'Tên lớp', value: 'placeTypeId' },
                        { text: 'Label', value: 'label' },
                        { text: 'Icon', value: 'icon' },
                        { text: 'Xóa', value: 'actions', align: 'center' },
                      ]"
                      :items="layers"
                      sort-by="calories"
                      disable-filtering
                      disable-pagination
                      disable-sort
                      hide-default-footer
                    >
                      <template v-slot:no-data> Chưa chọn lớp nào </template>
                      <template v-slot:item.placeTypeId="{ item }">
                        {{
                          placeTypes.find((i) => i.id === item.placeTypeId).name
                        }}
                      </template>
                      <template v-slot:item.name="{ item }">
                        {{ icons.find((i) => i.key === item.icon).name }}
                      </template>
                      <template v-slot:item.label="{ item }">
                        <span v-if="item.label"
                          >Màu:{{ item.color }}, offset:{{ item.offset }}, cỡ:
                          {{ item.textSize }}</span
                        >
                        <v-icon v-else>mdi-close</v-icon>
                      </template>
                      <template v-slot:item.icon="{ item }">
                        <img :src="`/storage/icons/${item.icon}.svg`" alt="" />
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-btn
                          color="red"
                          @click="layers.splice(item.id, 1)"
                          icon
                          ><v-icon>mdi-close</v-icon></v-btn
                        >
                      </template>
                    </v-data-table></v-col
                  >
                </v-row>
              </v-col>
              <v-col v-if="editing" cols="7" style="height: 600px" class="py-0">
                <iframe
                  v-if="displayIframe"
                  ref="iframe"
                  :src="`${this.mapUrl}?key=${this.form.key}`"
                  frameborder="0"
                  width="100%"
                  height="100%"
                ></iframe>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Huỷ</v-btn>
        <v-btn
          v-if="!editing"
          color="blue darken-1"
          :loading="loading"
          text
          @click="createData"
          >Thêm</v-btn
        >
        <v-btn
          v-else
          color="blue darken-1"
          :loading="loading"
          text
          @click="updateData"
          >Cập nhật</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { store, update } from "@/api/map";
import { index as getPlaceTypes } from "@/api/place-type";
import dialogMixin from "@/mixins/crud/dialog";
import { index as getIcons } from "@/api/icon";
import {} from "validator";

export default {
  mixins: [dialogMixin(store, update)],
  computed: {
    title() {
      return !this.editing ? "Thêm mới" : "Cập nhật";
    },
  },

  data() {
    return {
      layers: [],
      icons: [],
      layer: {
        icon: "",
        placeTypeId: "",
        label: false,
        color: "",
        offset: 1,
        textSize: 12,
      },
      placeTypes: [],
      loading: false,
      displayIframe: true,
      mapUrl: process.env.VUE_APP_MAP_URL,
    };
  },
  watch: {
    showDialog(val) {
      if (val) {
        console.log(this.form.layers);
        if (this.editing)
          this.layers = this.form.layers.map((item, idx) => ({
            placeTypeId: item.placeTypeId,
            icon: item.style.layout["icon-image"],
            id: idx,
            label: !!item.style.layout["text-field"],
            offset: item.style.layout["text-offset"][1],
            color: item.style.paint["text-color"],
            textSize: item.style.layout["text-size"],
          }));
      } else if (this.editing) this.$emit("updated");
    },
  },
  methods: {
    async getPlaceTypes() {
      this.placeTypes = (
        await getPlaceTypes({
          organizationCode: this.$route.params.code,
          sortBy: "name",
        })
      ).data;
    },
    async createData() {
      try {
        await this.$refs.form.validate();
        if (!this.valid) return;
        this.loading = true;
        await store({
          ...this.form,
          layers: [],
        });
        this.reload();
      } catch (error) {
        this.loading = false;
      }
    },
    async updateData() {
      try {
        await this.$refs.form.validate();
        if (!this.valid) return;
        this.loading = true;

        await update(this.form.id, {
          ...this.form,
          layers: this.layers,
          organizationCode: this.$route.params.code,
        });
        this.reload();
      } catch (error) {
        this.loading = false;
      }
    },
    reload() {
      this.loading = false;
      this.$snackbar(
        this.editing ? "Cập nhật thành công" : "Thêm mới thành công",
        "success"
      );

      if (this.editing) {
        this.resetIframe();
      } else {
        this.closeDialog();
        this.$emit(this.editing ? "updated" : "created");
      }
    },
    addLayer() {
      if (!this.layer.placeTypeId || !this.layer.icon)
        return this.$snackbar("Hãy chọn loại địa điểm và icon", "error");
      if (
        this.layers.find((item) => item.placeTypeId === this.layer.placeTypeId)
      )
        return this.$snackbar("Loại địa điểm đã được chọn", "error");
      if (this.layer.label) {
        if (!this.layer.color || !this.layer.offset)
          return this.$snackbar("Hãy chọn màu và offset", "error");
      }
      this.layers.push({ ...this.layer, id: this.layers.length });
      this.layer = {
        icon: "",
        placeTypeId: "",
        label: false,
        color: "",
        offset: 1,
        textSize: 12,
      };
    },
    resetIframe() {
      this.displayIframe = false;
      setTimeout(() => (this.displayIframe = true), 0);
    },
  },
  async created() {
    const { data } = await getIcons({
      organizationCode: this.$route.params.code,
    });
    this.icons = data;
    this.getPlaceTypes();
  },
};
</script>
