import request from "@/utils/request";

export function index(params) {
  return request({
    url: "/maps",
    method: "get",
    params
  });
}
export function update(id, data) {
  return request({
    url: "/maps/" + id,
    method: "put",
    data
  });
}

export function store(data) {
  return request({
    url: "/maps",
    method: "post",
    data
  });
}
export function destroy(id) {
  return request({
    url: "/maps/" + id,
    method: "delete"
  });
}
