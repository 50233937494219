<template>
  <div>
    <v-data-table
      :headers="getHeaders()"
      :items="tableData"
      disable-pagination
      fixed-header
      :options.sync="options"
      calculate-widths
      height="calc(100vh - 254px)"
      hide-default-footer
      :server-items-length="1"
      disable-filtering
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar dense class="custom-toolbar" flat>
          <v-toolbar-title style="font-size: 15px"
            >Tổng: {{ pagination.total }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('handle-create')"
            class="mx-2"
            x-small
            fab
            dark
            color="indigo"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | datetime }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          x-small
          @click="$emit('handle-edit', item)"
          fab
          dark
          color="primary"
        >
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          x-small
          @click="handleDelete(item.id)"
          fab
          dark
          color="error"
        >
          <v-icon dark>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          x-small
          @click="show(item.key)"
          fab
          dark
          color="blue"
        >
          <v-icon dark>mdi-eye-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="$emit('handle-reset')">Refresh</v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="pt-2">Nhúng bản đồ</v-card-title>
        <v-card-text>
          <v-textarea
            :value="iframe"
            label="Iframe"
            readonly
            rows="4"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="openMap" text>Mở map</v-btn>
          <v-btn color="blue darken-1" @click="clipboard(iframe)" text
            >Copy Iframe</v-btn
          >
          <v-btn color="blue darken-1" text @click="dialog = false">Đóng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { destroy } from "@/api/map";
import dataTableMixin from "@/mixins/crud/data-table";
export default {
  mixins: [dataTableMixin(destroy)],
  data() {
    return {
      dialog: false,
      mapUrl: "",
    };
  },
  computed: {
    headers() {
      return [
        { text: "Tên", value: "name" },
        { text: "Key", value: "key" },
        { text: "Thời gian tạo", value: "createdAt" },

        {
          text: "Hành động",
          value: "actions",
          align: "center",
          sortable: false,
          width: "150px",
        },
      ];
    },
    iframe() {
      return `<iframe src="${this.mapUrl}" frameborder="0" width="100%" height="100%" ></iframe>`;
    },
  },
  methods: {
    show(key) {
      this.mapUrl = process.env.VUE_APP_MAP_URL + `/?key=${key}`;
      this.dialog = true;
    },
    openMap() {
      window.open(this.mapUrl, "_blank");
    },
    clipboard(str) {
      const el = document.createElement("textarea");
      el.addEventListener("focusin", (e) => e.stopPropagation());
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$snackbar("Đã copy iframe thành công", "success");
    },
  },
};
</script>
